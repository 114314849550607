import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Logo} from "./Logo";
import {useUser} from "../UserContext";

const Header = () => {
    const {user, setUser} = useUser();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const gamesession = queryParams.get("gamesession") || null;
    const deeplink = queryParams.get("back") || null;
    const isActive = (pathname) => {
        if (pathname === "/" && location.pathname === "/") {
            return true;
        } else if (pathname !== "/" && location.pathname.startsWith(pathname)) {
            return true;
        }
        return false;
    };

    return (
        <div className="w-full flex flex-row justify-around items-center h-[97px] bg-header">
            <div>
                <Link to="/">
                    <Logo className="w-[50px] h-[50px] md:h-[72px] md:w-[72px]"/>
                </Link>
            </div>

            <div
                id="mainMenu"
                className="flex gap-2 lg:gap-4 flex-row text-[14px] md:text-[20px] h-[40px] lg:h-[50px] items-center [font-family:'Anton',Helvetica] font-normal text-white"
            >
                <div>
                    <Link
                        className="menuLink"
                        style={{
                            borderBottom: isActive("/match") ? "3px solid #D2FF00" : "",
                        }}
                        to="/match"
                    >
                        MATCHES
                    </Link>
                </div>

                <div>
                    <Link
                        className="menuLink"
                        style={{
                            borderBottom: isActive("/games") ? "3px solid #D2FF00" : "",
                        }}
                        to="/games"
                    >
                        PLAY
                    </Link>
                </div>
                <div>
                    <Link
                        className="menuLink"
                        style={{
                            borderBottom:
                                isActive("/media") ||
                                isActive("/videos") ||
                                isActive("/podcasts")
                                    ? "3px solid #D2FF00"
                                    : "",
                        }}
                        to="/media"
                    >
                        MEDIA
                    </Link>
                </div>
            </div>

            <div className="text-[14px] md:text-[20px] [font-family:'Anton',Helvetica] font-normal text-white">
                {!user ? (
                    <div className="flex flex-row h-full">
                        <Link
                            to={`/login${gamesession ? `?gamesession=${gamesession}` : ""}${
                                deeplink ? `&back=${deeplink}` : ""
                            }`}
                        >
                            <div
                                className="bg-lime h-full w-[65px] lg:w-[80px] flex justify-center items-center text-center">
                                <div
                                    className="font-anton text-main text-[14px] md:text-[20px] px-[5px] py-[10px]">LOGIN
                                </div>
                            </div>
                        </Link>
                        <Link
                            to={`/signup${gamesession ? `?gamesession=${gamesession}` : ""}${
                                deeplink ? `&back=${deeplink}` : ""
                            }`}
                        >
                            <div
                                className="bg-lime h-full w-[65px] lg:w-[80px] ml-[3px] flex justify-center items-center text-center">
                                <div
                                    className="px-[5px] py-[10px] font-anton text-main text-[14px] md:text-[20px]">SIGNUP
                                </div>
                            </div>
                        </Link>
                    </div>
                ) : (
                    <div>
                        <Link to="/account">
                            <div className="bg-lime flex justify-center items-center h-full pl-[10px] pr-[5px]">
                                <div className="justify-center flex items-center">
                                    <div className="font-anton text-main text-[14px] md:text-[20px] px-[5px] py-[10px]">
                                        ACCOUNT
                                    </div>
                                    <div className="ml-[10px] bg-lime p-1">
                                        <img src={user.profile_pic} className="w-[36px] h-[36px]"/>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
